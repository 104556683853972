<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.032 10.405L13.657 1.02997C13.2175 0.590583 12.6215 0.34375 12 0.34375C11.3785 0.34375 10.7825 0.590583 10.343 1.02997L0.967981 10.405C0.749458 10.6222 0.576231 10.8806 0.458348 11.1652C0.340465 11.4499 0.280273 11.7551 0.281262 12.0632V23.3132C0.281262 23.6861 0.42942 24.0438 0.693143 24.3075C0.956866 24.5713 1.31455 24.7194 1.68751 24.7194H9.18751C9.56047 24.7194 9.91816 24.5713 10.1819 24.3075C10.4456 24.0438 10.5938 23.6861 10.5938 23.3132V17.2194H13.4063V23.3132C13.4063 23.6861 13.5544 24.0438 13.8181 24.3075C14.0819 24.5713 14.4396 24.7194 14.8125 24.7194H22.3125C22.6855 24.7194 23.0432 24.5713 23.3069 24.3075C23.5706 24.0438 23.7188 23.6861 23.7188 23.3132V12.0632C23.7198 11.7551 23.6596 11.4499 23.5417 11.1652C23.4238 10.8806 23.2506 10.6222 23.032 10.405ZM20.9063 21.9069H16.2188V15.8132C16.2188 15.4402 16.0706 15.0825 15.8069 14.8188C15.5432 14.5551 15.1855 14.4069 14.8125 14.4069H9.18751C8.81455 14.4069 8.45687 14.5551 8.19314 14.8188C7.92942 15.0825 7.78126 15.4402 7.78126 15.8132V21.9069H3.09376V12.2565L12 3.35028L20.9063 12.2565V21.9069Z"
      fill="white"
    />
  </svg>
</template>
